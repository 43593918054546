<template>
  <div class="container" style="height: 100vh;" @contextmenu.prevent>
      <div class="row">
        <div class="col-12">
          <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
          <div class="card" style="min-height: 80vh">
            <div class="card-body">
              <h1 class="card-title" style="margin-top: 20px; margin-bottom: 20px;">Problemas de Opción Múltiple</h1>
              <div class="topics mb-3">
                <button
                  class="btn"
                  :class="allActive ? 'btn-primary' : 'btn-outline-secondary'"
                  @click="activateAll"
                >
                  Todas
                </button>
                <button
                  v-for="(topic, index) in topics"
                  :key="index"
                  :class="['btn', topic.active && !allActive ? 'btn-primary' : 'btn-outline-secondary']"
                  @click="toggleTopic(index)"
                >
                  {{ topic.name }}
                </button>
              </div>
              <h5 v-if="!noTopicSelected" class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">{{currentCategory}}</h5>
              <div v-if='this.isSubscribed==1 || this.remainingCredits.section1 >0' class="problem-container">
                <img v-show='has_figure==1 && !noTopicSelected' class="problem-image" :src="`https://mathpaas-documents.s3.amazonaws.com/images/${this.draw}.png`" alt="Assessment image">
                <div v-if="isLoading" class="loading-animation">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Cargando...</span>
                </div>
                </div>                
                <div v-else-if="noTopicSelected" class="placeholder-text">
                  Selecciona una categoría para practicar
                </div>
                <template v-else>
                  <div
                :class="!isMobile() ? 'formatted-text-wrapper' : 'formatted-text-wrapper-mobile'">
                  <FormattedText :content="problem"/>
                </div>
                    <div class="options" style="margin-top: 30px;">
                <div
                    class="form-check"
                    v-for="(option, index) in options"
                    :key="index"
                >
                    <input
                    class="form-check-input"
                    type="radio"
                    name="option"
                    :id="'option' + index"
                    :value="index"
                    v-model="selectedOption"
                    />
                    <label
                    class="form-check-label"
                    :for="'option' + index"
                    ><div>
                      <FormattedText :content="option.label + '. ' + option.text"/>
                    </div> </label>
                </div>
                </div>
            </template>
            <div class="buttons" style="margin-top: 30px;" v-if="!isMobile()">
                <div class="buttons-left">
                    <button class="btn btn-secondary" style="margin-right:5px;" @click="backToDashboard">Regresar</button>
                </div>
                <div class="buttons-right" v-if="!noTopicSelected && !isLoading">
                    <button class="btn btn-primary equal-width" @click="submitAnswerInModal">Enviar</button>
                    <button class="btn btn-info equal-width text-white" @click="showHintInModal">Pistas ({{ remainingHints }})</button>
                    <button class="btn btn-secondary equal-width" @click="showExplanationInModal">Ayuda</button>
                </div>
            </div>
            </div>
            <div v-else  class="problem-container">
              <div class="placeholder-text">
                Suscríbete para continuar practicando
              </div>
              <button class="btn btn-primary" style="margin-top: 40px" @click="goToPaymentPlans">Suscribirme</button>
            </div>
            <div style="margin-top: 30px;" v-if="isMobile()">
                <div v-if="!noTopicSelected && !isLoading">
                    <button class="btn btn-primary" style="width: 60%; height: 40px;" @click="submitAnswerInModal">Enviar</button>
                </div>
                <div  class="d-flex flex-row justify-content-between" v-if="!noTopicSelected && !isLoading" style="margin-top: 20px;">
                    <button class="btn btn-info equal-width text-white" @click="showHintInModal" style="width: 45%; height: 40px;">Pistas ({{ remainingHints }})</button>
                    <button class="btn btn-secondary equal-width" @click="showExplanationInModal" style="width: 45%; height: 40px;">Ayuda</button>
                </div>
                <div>
                    <button class="btn btn-secondary" style="width: 60%; height: 40px; margin-top: 20px;" @click="backToDashboard">Regresar</button>
                </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="resultModal" tabindex="-1" aria-labelledby="resultModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="resultModalLabel">{{ modalTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="!isChatMode">
          <FormattedText :content="modalMessage"/>
        </div>
        <div v-else class="chat-container">
          <div class="messages">
            <div v-for="(message, index) in chatMessages" :key="index" class="message">
              <div :class="message.sender === 'user' ? 'user-message' : 'assistant-message'">
                <FormattedText :content="message.content"/>
              </div>
            </div>
            <div v-if="isWriting" class="message">
                <div class="assistant-message" style="width: 60px; height: 40px;">
                <div class="loader" style="margin-top: -10px;"></div>
                </div>
            </div>
          </div>
          <div class="input-area">
            <input v-model="userInput" @keyup.enter="sendMessage" type="text" class="form-control" placeholder="Type your message here...">
            <button @click="sendMessage" class="btn btn-primary">Enviar</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal" v-if="showContinueButton">Continuar</button>
        <button type="button" class="btn btn-primary" @click="fetchAndCloseModal" v-if="showNextProblemButton">Siguiente</button>
        <button type="button" class="btn btn-primary" @click="showExplanationAfterHints" v-if="showExplanationButton">Ayuda</button>  
    </div>
    </div>
  </div>
</div>
  </template>

  <script>
  import axios from 'axios';
  import { Modal } from "bootstrap";
  import { mapState,mapActions } from 'vuex';
  import FormattedText from "@/components/FormattedText.vue";


  export default {

    components: {

      FormattedText,

    },

    data() {
      return {
        allActive: true,
        problem: '',
        isLoading: false,
        options: [],
        selectedOption: null,
        resultMessage: "",
        answer: '',
        explanation: '',
        hints: [],
        remainingHints: 0,
        p5Instance: null,
        has_figure: 0,
        figureCode: '',
        setup: ``,
        draw: '',
        details: [],
        currentCategory: '',
        topics: [
          { name: "Aritmética", category: "Aritmética", active: true },
          { name: "Álgebra", category: "Álgebra", active: true },
          { name: "Geometría", category: "Geometría", active: true },
          { name: "Probabilidad", category: "Análisis de Datos y Probabilidad", active: true },
          //{ name: "Observar", category: "Ser Observador", active: true },
        ],
        modalTitle: '',
        modalMessage: '',
        showContinueButton: false,
        showExplanationButton: false,
        showNextProblemButton: false,
        shouldFetchNewProblem: false,
        isChatMode: false,
        userInput: '',
        chatMessages: [],
        isWriting: false,
        userImage: require('@/assets/user.png'),
        HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    mounted() {

    
      this.preventScreenshotShortcuts();


    this.fetchDBProblem();

    document.getElementById("resultModal").addEventListener("hidden.bs.modal", () => {
    if (this.shouldFetchNewProblem) {
      this.fetchDBProblem();
    }
    // Reset shouldFetchNewProblem to false
    this.shouldFetchNewProblem = false;
  });
  },


  watch: {
    topics: {
      handler() {
        const currentCategoryIsActive = this.topics.some(
          (topic) => topic.category === this.currentCategory && topic.active
        );

        if (!currentCategoryIsActive) {
          this.fetchDBProblem();
        }
      },
      deep: true,
    },
  },

  computed: {
  
    ...mapState(['email','userId','isSubscribed','remainingCredits']),

  noTopicSelected() {
    return !this.topics.some((topic) => topic.active);
  },
},
  
    methods: {

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

    preventScreenshotShortcuts() {
      document.addEventListener("keydown", this.disableShortcuts);
    },

    removePreventScreenshotShortcuts() {
      document.removeEventListener("keydown", this.disableShortcuts);
    },

    disableShortcuts(event) {
      const key = event.key.toLowerCase();
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const isCmdOrCtrl = (isMac && event.metaKey) || (!isMac && event.ctrlKey);

      if (
        (isCmdOrCtrl && (key === "s" || key === "p" || key === "f")) || // Disable Ctrl/Cmd + S, P, and F
        (key === "printscreen") || // Disable Print Screen key
        (event.altKey && key === "printscreen") || // Disable Alt + Print Screen
        (isMac && event.shiftKey && event.ctrlKey && (key === "s" || key === "4")) // Disable Shift + Ctrl + S or 4 on Mac
      ) {
        event.preventDefault();
      }
    },
      

      goToPaymentPlans() {
    // Navigate to the Score Report History view
    this.$router.push("/payment-plan");
  },

      ...mapActions(['updateRemainingCredits']),

      updateMultipleChoiceCreditsInDatabase() {
    axios
      .put(`${this.$baseURL}/api/update-multiple-choice-credits`, {
        user_id: this.userId,
        multiple_choice_credits: this.remainingCredits.section1,
      })
      .catch((error) => {
        console.error("Error updating multiple choice credits:", error);
      });
  },

    async sendMessage() {
    if (this.userInput.trim() === '') return;
    this.chatMessages.push({ sender: 'user', content: this.userInput });
    
    const gptPrompt = this.chatMessages;
    this.userInput = '';

    const gptResponse = await this.fetchGPTResponse(gptPrompt.response);
    this.chatMessages.push({ sender: 'assistant', content: gptResponse });
  },

        backToDashboard() {
      // Handle navigation back to dashboard view here
      this.$router.push('/student-dashboard');
    },

    showModal(title, message) {
    this.modalTitle = title;
    this.modalMessage = message;
    const modal = new Modal(document.getElementById("resultModal"));
    modal.show();
  },

  showExplanationAfterHints(){
    const modal = Modal.getInstance(document.getElementById("resultModal"));
    modal.hide();
    this.showExplanationInModal();
  },

  fetchAndCloseModal() {
    const modal = Modal.getInstance(document.getElementById("resultModal"));
    modal.hide();
  },

  showHintInModal() {
    this.isChatMode = false;
  if (this.remainingHints === 0) {
    this.showModal("Pista", "Ya no hay mas pistas. Da clic en el boton de ayuda para recibir una explicacion detallada de nuestro asistente virtual.");
    this.showNextProblemButton = false;
    this.showExplanationButton = true;
    this.showContinueButton = true;
  } else {
    this.showModal("Pista", this.hints[this.hints.length - this.remainingHints]);
    this.remainingHints -= 1;
    this.showNextProblemButton = false;
    this.showExplanationButton = false;
    this.showContinueButton = true;
  }
},

  submitAnswerInModal() {
    this.isChatMode = false;
    if (this.selectedOption === null) {
      this.modalTitle = "Error";
      this.modalMessage = "Por favor selecciona una de las respuestas de opcion multiple";
      this.showModal(this.modalTitle, this.modalMessage);
      this.showNextProblemButton = false;
      this.showExplanationButton = false;
      this.showContinueButton = true;
      this.shouldFetchNewProblem = false;
      return;
    }
    else {
        this.shouldFetchNewProblem = true;
    }

    if (this.selectedOption == this.answer) {
      this.modalTitle = "Resultado";
      this.modalMessage = "Correcto!";
      this.showNextProblemButton = true;
      this.showExplanationButton = false;
      this.showContinueButton = false;
      this.showModal(this.modalTitle, this.modalMessage);
    } else {
      this.modalTitle = "Resultado";
      this.modalMessage = `Incorrecto! La respuesta correcta es: ${this.options[this.answer].label}.`;
      this.showNextProblemButton = true;
      this.showExplanationButton = true;
      this.showContinueButton = false;
      this.showModal(this.modalTitle, this.modalMessage);
    }
  },


  showExplanationInModal() {
    this.chatMessages = [];
    this.isChatMode = true;
    this.shouldFetchNewProblem = true;
    this.modalTitle = "Asistente"
    this.showModal(this.modalTitle, this.explanation);
    this.showNextProblemButton = true;
    this.showExplanationButton = false;
    this.showContinueButton = false;
  this.chatMessages.push({ sender: 'assistant', content: this.explanation });
  this.chatMessages.push({ sender: 'assistant', content: 'Tienes alguna duda?' });
},
  

  closeModal() {
      const modal = Modal.getInstance(document.getElementById("resultModal"));
      modal.hide();
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("modal-open");
    },

        getRandomActiveTopic() {
      const activeTopics = this.topics.filter((topic) => topic.active);
      const randomIndex = Math.floor(Math.random() * activeTopics.length);
      return activeTopics[randomIndex].category;
    },

    async fetchDBProblem() {
        if (this.noTopicSelected) {
    this.problem = '';
    this.options = [];
    this.currentCategory = '';
    return;
  }
      this.isLoading = true;
      this.remainingHints = 0;
      this.selectedOption = null;

      try {
        this.currentCategory = this.getRandomActiveTopic();
        const category = this.currentCategory;
        const is_open_answer = 0;
        const response = await axios.get(`${this.$baseURL}/api/get-DB-problem`, {
          params: { category, is_open_answer},
        });

        if (response.data.success) {
          if(this.isSubscribed==0){
            this.updateRemainingCredits({section: 1, credits: this.remainingCredits.section1-1});
            this.updateMultipleChoiceCreditsInDatabase();
          }
            const jsonObject = response.data.data;
            const tempOptions = JSON.parse(jsonObject.Options);
            const optionLabels = Array.from({ length: tempOptions.length }, (_, i) => String.fromCharCode(65 + i));
            this.explanation= jsonObject.Explanation;
            //this.figure = jsonObject.Figure;
            //this.details = jsonObject.Details;
            this.hints = JSON.parse(jsonObject.Hints);
            this.remainingHints = this.hints.length;
            this.problem = jsonObject.Problem;
            this.options = tempOptions.map((option, index) => {
                return {
                label: optionLabels[index],
                text: option,
                };
            });
            this.answer = jsonObject.Answer;
            this.has_figure = jsonObject.hasFigure;
            this.setup = jsonObject.Setup;
            this.draw = jsonObject.Draw;
            this.isLoading = false;


        } else {
          throw new Error('Error fetching problem');
        }
      } catch (error) {
        console.error(error);
      }
    },


    
    async fetchProblem() {
        if (this.noTopicSelected) {
    this.problem = '';
    this.options = [];
    this.currentCategory = '';
    return;
  }
      this.isLoading = true;
      this.remainingHints = 0;
      this.selectedOption = null;
      this.chatMessages = [];

      try {
        this.currentCategory = this.getRandomActiveTopic();
        const category = this.currentCategory;
        const response = await axios.get(`${this.$baseURL}/api/generate-problem`, {
          params: { category },
        });

        if (response.data.success) {
            const jsonObject = JSON.parse(response.data.problem);
            const optionLabels = Array.from({ length: jsonObject.Options.length }, (_, i) => String.fromCharCode(65 + i));

            this.explanation= jsonObject.Explanation;
            this.figure = jsonObject.Figure;
            this.details = jsonObject.Details;
            this.hints = jsonObject.Hints;
            this.remainingHints = this.hints.length;
            this.problem = jsonObject.Problem;
            this.options = jsonObject.Options.map((option, index) => {
                return {
                label: optionLabels[index],
                text: option,
                };
            });
            this.answer = jsonObject.Answer;
            this.isLoading = false;

        } else {
          throw new Error('Error fetching problem');
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchGPTResponse() {

      this.isWriting = true;

      try {
        const conversation = this.chatMessages;
        const problem = this.problem;
        const response = await axios.get(`${this.$baseURL}/api/generate-response`, {
          params: { conversation, problem },
        });

        if (response.data.success) {
            this.isWriting = false;

            return response.data.response;

        } else {
          throw new Error('Error fetching GPTResponse');
        }
      } catch (error) {
        console.error(error);
      }
    },

      activateAll() {
        this.allActive = true;
        this.topics.forEach((topic) => (topic.active = true));
      },
      toggleTopic(index) {
  if (this.allActive) {
    this.allActive = false;
    this.topics.forEach((topic, i) => {
      if (i === index) {
        topic.active = true;
      } else {
        topic.active = false;
      }
    });
  } else {
    this.topics[index].active = !this.topics[index].active;
    if (this.topics.every((topic) => topic.active)) {
      this.allActive = true;
    }
  }
},
    },

  };
  </script>
  
  <style>

.problem-image {
    width: auto;    /* Set the width of the image to 40% of the container */
    height: 250px;  /* Maintain the aspect ratio */
}
    .container {
      height: 100vh;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    
    .card {
      width: 100%;
      background-color: white;
    }
    
    .card-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    .topics button {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 120px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

      /* New styles for the problem and options container */
  .problem-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .problem-text {
    text-align: justify;
    margin-bottom: 20px;
    max-width: 60%;
  }
  
  .katex {
    font: arial;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
  }
  
  /* New styles for the submit and help buttons */
  
  .buttons button.equal-width {
  margin-right: 5px;
  width: 100px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
}

.result-message {
  margin-top: 20px;
  text-align: justify;
  max-width: 60%;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}

.placeholder-text {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: grey;
  margin-top: 100px;
}

.modal .modal-header,
.modal .modal-footer {
  border: none !important;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal.fade .modal-dialog {
  transform: translateY(-50%);
}

.modal.show .modal-dialog {
  transform: translateY(0);
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 60%;
}

.buttons-left {
  display: flex;
}

.buttons-right {
  display: flex;
  justify-content: flex-end;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.5rem;
  height: 400px;
}

.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.input-area {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.input-area input {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: 400px;
  padding: 1rem;
}

.user-message {
  text-align: left;  
  background-color: #cce5ff;
  padding: 0.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-bottom: 5px;
  width: 90%;
}

.assistant-message {
  text-align: left;  
  background-color: #e2e3e5;
  padding: 0.5rem;
  border-radius: 5px;
  margin-right: auto;
  margin-bottom: 5px;
  width: 90%;
}

.message {
  display: flex;
}

.message.assistant {
  justify-content: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation-fill-mode: both;
  animation: bblFadInOut 1.2s infinite ease-in-out;
}
.loader {
  color: #fff;
  font-size: 7px;
  position: relative;
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: 15px;
  animation-delay: 0.3s;
}
.loader:after {
  left: 30px;
  animation-delay: 0.6s;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
  </style>
  